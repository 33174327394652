import { useState, useRef, useEffect } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Select,
  Input,
  Box,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import {
  SystemConst,
  AreaTagetNames,
  AreaOptionAry,
  IAnnouncementModalRegForm,
  IAnnouncementBody,
  IBaseInfo,
  IBaseItem,
  DwCustomerCodeHash,
  isValidPeriod,
  isEventAreaShare,
  isEventAreaDepartment,
} from "../SystemConst";
import { MyDateTimePicker } from "./MyDateTimePicker";
import { MyAlertDialog } from "./MyAlertDialog";
import { InfoIcon, EditIcon } from "@chakra-ui/icons";
import { MyInfoShareCheckBox } from "./MyInfoShareCheckBox";
import { MyEventAreaCheckBox } from "./MyEventAreaCheckBox";
import BaseMaster from "../../master/baseData.json";

export const AnnouncementModalRegForm: React.FunctionComponent<{
  param: IAnnouncementModalRegForm;
}> = ({ param }) => {
  const headStr: string = param.props.id ? "お知らせを編集" : "お知らせを作成";
  const buttonLabel: string = param.props.id ? "編集" : "登録";
  const areaSelectIsDisabled: boolean = param.props.id ? true : false;
  // ログインユーザの所属を元にArea Optionを作成
  const areaOptions =
    param.userDepartment === SystemConst.AdminDepartment
      ? AreaOptionAry
      : [
          {
            name: AreaTagetNames[param.userDepartment],
            value: param.userDepartment,
          },
        ];
  const [id, setId] = useState(0);
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [area, setArea] = useState("");
  const [fromDate, setFromDate] = useState<Date>(param.props.fromDate);
  const [toDate, setToDate] = useState<Date | undefined>(
    param.props.toDate ? param.props.toDate : undefined,
  );

  const [shareStatus, setShareStatus] = useState(0);
  const [shareBases, setShareBases] = useState<{ code: string }[]>([]); // 連携対象の拠点Codeオブジェクト配列
  const [validPeriodColor, setValidPeriodColor] = useState("gray:600");
  const getValidPeriod2ShareIconColor = (fDate: Date, tDate?: Date) => {
    const res = isValidPeriod(fDate, tDate);
    let colorStr = "gray.400"; //期間終了
    if (res === 1) {
      colorStr = "red.600"; // 有効
    } else if (res === -1) {
      colorStr = "blue.400"; //予定
    }
    return colorStr;
  };

  const [eventAreaValue, setEventAreaValue] = useState(param.props.event_area); // add 20240510 NEXCO３社連携
  const [importanceValue, setImportanceValue] = useState(
    param.props.importance,
  ); // add 20240510 NEXCO３社連携

  useEffect(() => {
    setId(param.props.id);
    setTitle(param.props.title);
    setUrl(param.props.url);
    setArea(param.props.area);
    setFromDate(param.props.fromDate);
    setToDate(param.props.toDate ? param.props.toDate : undefined);

    setShareStatus(param.props.shareStatus);
    setShareBases(param.props.shares ? param.props.shares : []);
    setValidPeriodColor(
      getValidPeriod2ShareIconColor(
        param.props.fromDate,
        param.props.toDate ? param.props.toDate : undefined,
      ),
    );

    setEventAreaValue(param.props.event_area); // add 20240510 NEXCO３社連携
    setImportanceValue(param.props.importance); // add 20240510 NEXCO３社連携
  }, [param.props]);

  const inputTitleEl = useRef<HTMLInputElement>(null);
  const focusEl = useRef<HTMLButtonElement>(null);

  // 登録処理
  const handleRegAction = async (params: IAnnouncementBody) => {
    try {
      await param.funcRegAction(id, params);
      await param.funcClose();
      await param.funcRefresh();
    } catch (error) {
      alert("登録に失敗しました");
    }
  };
  const handleFuncClose = () => {
    param.funcClose();
  };

  const handleFromDateOnChange = (dt: Date) => {
    //console.info("handleFromDateOnChange");
    setValidPeriodColor(getValidPeriod2ShareIconColor(dt, toDate));
    setFromDate(dt);
  };
  const handleToDateOnChange = (dt: Date | undefined) => {
    setValidPeriodColor(getValidPeriod2ShareIconColor(fromDate, dt));
    setToDate(dt);
  };

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const onAlertClose = () => setIsAlertOpen(false);
  const [validMessage, setValidMessage] = useState("");
  const showAlertToFocusElememt = (
    msg: string,
    el: string | React.RefObject<HTMLElement>,
  ) => {
    setValidMessage(msg);
    setIsAlertOpen(true);
    throw Error("throw: Show Alert Dialog");
  };

  // 情報連携機能の対象会社（エリア）か判定
  const isValidShareArea = (a: string) => {
    if ( 
      a === SystemConst.DepartmentCENTER // NEXCO中日本
    ) {
      return true; // 対象
    }
    return false;
  };
  const {
    isOpen: isOpenShare,
    onOpen: onOpenShare,
    onClose: onCloseShare,
  } = useDisclosure();

  const [shareBaseList, setShareBaseList] = useState<IBaseInfo[]>();
  const onOpenShareSetting = async () => {
    // 拠点リストマスター取得する
    let masterSetting = BaseMaster.data;
    //console.debug("BaseList: " + BaseMaster.createAt);

    // お知らせの拠点情報(連携の有無)を、各拠点に紐付けたデータを生成
    const branchBaseStatusList: IBaseInfo[] = [];
    if (isValidShareArea(area) && DwCustomerCodeHash[area]) {
      // 会社Loop
      for (let i = 0; i < masterSetting.length; i++) {
        // 連携機能が有効な会社（2023/7 のリリース時点：NEXCO中日本）
        if (
          masterSetting[i].customerCode.toLowerCase() ===
          DwCustomerCodeHash[area]
        ) {
          // 支社Loop
          for (let s = 0; s < masterSetting[i].branches.length; s++) {
            const tmpBaseList: IBaseItem[] = [];
            // 拠点のLoop
            for (
              let t = 0;
              t < masterSetting[i].branches[s].baseList.length;
              t++
            ) {
              // 拠点コードを小文字に変換（DB登録形式）
              const code: string =
                masterSetting[i].branches[s].baseList[t].code.toLowerCase();

              // 連携の有無をチェック
              let bFlg = false;
              for (let v = 0; v < shareBases.length; v++) {
                if (shareBases[v].code === code) {
                  bFlg = true;
                  break;
                }
              }

              let item: IBaseItem = {
                code: code,
                name: masterSetting[i].branches[s].baseList[t].name,
                status: bFlg,
              };
              tmpBaseList.push(item);
            }
            const tmpBaseInfo: IBaseInfo = {
              branchCode: masterSetting[i].branches[s].branchCode,
              branchName: masterSetting[i].branches[s].branchName,
              baseList: tmpBaseList,
            };
            branchBaseStatusList.push(tmpBaseInfo);
          }
        }
      }
    }
    setShareBaseList(branchBaseStatusList);

    onOpenShare();
  };
  // 選択拠点の結果を受け取る（チェックONの拠点Code配列）
  const handleCangeShareSetting = (res: string[]) => {
    let result = 0;
    if (res.length > 0) {
      if (area === SystemConst.DepartmentWEST) {
        // NEXCO西日本
        result = result ^ 1;
      } else if (area === SystemConst.DepartmentCENTER) {
        // NEXCO中日本
        result = result ^ 2;
      } else if (area === SystemConst.DepartmentEAST) {
        // NEXCO東日本
        result = result ^ 4;
      } else if (area === SystemConst.DepartmentTOKYO) {
        // 首都高
        result = result ^ 8;
      } else if (area === SystemConst.DepartmentNAGOYA) {
        // 名古屋高速
        result = result ^ 16;
      } else if (area === SystemConst.DepartmentHANSHIN) {  // 20241204 阪神高速追加
        // 阪神高速
        result = result ^ 32;
      }
    }
    setShareStatus(result);

    let tmpRes: { code: string }[] = [];
    if (res) {
      tmpRes = res.map((el) => {
        return { code: el };
      });
    }
    setShareBases(tmpRes);
    setValidPeriodColor(getValidPeriod2ShareIconColor(fromDate, toDate));
    onCloseShare();
  };
  const createAreaHtml = () => {
    return param.userDepartment === SystemConst.AdminDepartment ? (
      <FormControl id="area" isRequired pt={5}>
        <FormLabel>エリア</FormLabel>
        <Select
          isDisabled={areaSelectIsDisabled}
          value={area}
          onChange={(e) => {
            setArea(e.target.value);
            setShareBases([]); // エリアが変わったので選択拠点をクリア
            setShareStatus(0); // エリアが変わり選択拠点がクリアされたので選択拠点数をクリア

            setEventAreaValue(0)  // エリアが変わったので、事象発生エリアの選択をクリア 20240510 add
            setImportanceValue((isEventAreaShare) ? 2 : 0) // エリアが変わったので、情報重要度をデフォルト値に設定 20240510 add
          }}
        >
          {areaOptions.map((area, index) => (
            <option value={area.value} key={index}>
              {area.name}
            </option>
          ))}
        </Select>
      </FormControl>
    ) : (
      <FormControl id="area">
        <Input type="hidden" value={param.userDepartment} />
      </FormControl>
    );
  };

  // NEXCO３社連携対象会社か判定
  const isValidEventAreaShareDepartment = (a: string) => {
    return isEventAreaDepartment(a);   
  };
  // 対象エリア選択結果を受け取る（チェックONのエリア（マスク値））
  const handleChangeEventArea = (res: number) => {
    setEventAreaValue(res);
    //console.info(res)
  };
  // 情報重要度の選択結果を受け取る
  const handleChangeImportansce = (res: number) => {
    setImportanceValue(res);
    //console.info(res)
  };
  const createEventAreaHtml = () => {
    if (isEventAreaShare && isValidEventAreaShareDepartment(area)) {
      return (
        <MyEventAreaCheckBox
          area={area}
          status={{
            eventAreaMask: eventAreaValue,
            importance: importanceValue,
          }}
          handleOnChangeEventArea={handleChangeEventArea}
          handleOnChangeImportansce={handleChangeImportansce}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      {/**** 登録編集From ****/}
      <Modal
        closeOnOverlayClick={false}
        scrollBehavior="outside"
        isOpen={param.isOpenFlag}
        onClose={handleFuncClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{headStr}</ModalHeader>
          <ModalCloseButton ref={focusEl} />
          <ModalBody pb={{ base: 20, md: 5 }}>
            <FormControl id="title" isRequired>
              <FormLabel>タイトル</FormLabel>
              <Input
                type="text"
                value={title}
                ref={inputTitleEl}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </FormControl>
            <FormControl id="url" pt={5} isRequired>
              <FormLabel>URL</FormLabel>
              <Input
                type="text"
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value);
                }}
              />
            </FormControl>
            {createAreaHtml()}
            {createEventAreaHtml()}
            <FormControl id="validPeriod" isRequired pt={5}>
              <FormLabel>掲載期間</FormLabel>
              {/* <Box display={{ md: "flex" }} alignItems="center" w={"100vw"}> */}
              <Box display={"flex"} alignItems="center" w={"100%"}>
                {/* <Box display="flex" alignItems="center"> */}
                  {/* <Box display={{ md: "none" }} mr="3">
                    From :
                  </Box> */}
                  <MyDateTimePicker
                    id="inputFromDate"
                    placeholder="開始日（必須）"
                    initialvalue={param.props.fromDate}
                    isClear={false}
                    onChange={handleFromDateOnChange}
                  />
                {/* </Box> */}
                <Box ml={3} mr={3}>
                  〜
                </Box>
                {/* <Box display="flex" alignItems="center"> */}
                  {/* <Box display={{ md: "none" }} mr="3">
                    To :
                  </Box> */}
                  <MyDateTimePicker
                    id="inputToDate"
                    placeholder="終了日（任意）"
                    initialvalue={
                      param.props.toDate ? param.props.toDate : undefined
                    }
                    isClear={true}
                    onChange={handleToDateOnChange}
                  />
                {/* </Box> */}
              </Box>
            </FormControl>
            {
              // 情報連携の対象会社（エリア）のみ表示
              isValidShareArea(area) ? (
                <FormControl id="infoshare" pt={5}>
                  <Button
                    size="sm"
                    leftIcon={
                      shareStatus === 0 ? (
                        <Icon as={EditIcon} />
                      ) : (
                        <Icon as={InfoIcon} color={validPeriodColor} />
                      )
                    }
                    onClick={async () => {
                      onOpenShareSetting();
                    }}
                  >
                    情報連携設定
                  </Button>
                </FormControl>
              ) : (
                <></>
              )
            }
            <Button
              mt={5}
              colorScheme="teal"
              size="md"
              onClick={async () => {
                try {
                  if (!title.trim()) {
                    showAlertToFocusElememt(
                      "[タイトル]を入力してください",
                      inputTitleEl,
                    );
                  }
                  // 20240617 必須チェックON に変更
                  if (!url) {
                    showAlertToFocusElememt(
                      "[URL]を入力してください",
                      "url",
                    );
                  }else{
                    const regex = new RegExp("^(https?|http)(://).+");
                    if(!regex.test(url.toLowerCase())){
                      showAlertToFocusElememt(
                        "[URL]は \"https://〜\" 又は \"http://〜\" の形式で入力してください",
                        "url",
                      );
                    }
                  }
                  if (!fromDate) {
                    showAlertToFocusElememt(
                      "[掲載期間（開始日）]を指定してください",
                      "inputFromDate",
                    );
                  }
                  if (toDate && fromDate >= toDate) {
                    showAlertToFocusElememt(
                      "[掲載期間]は（From < To）を指定してください",
                      "inputToDate",
                    );
                  }
                  // 20240617 必須チェックON に変更
                  // if (shareStatus > 0) {
                  //   if (!url) {
                  //     showAlertToFocusElememt(
                  //       "情報連携する場合は[URL]の指定が必要です",
                  //       "url",
                  //     );
                  //   }
                  // }

                  // add 20240510 NEXCO３社連携
                  if (isEventAreaShare && isValidEventAreaShareDepartment(area)) {
                    if (!eventAreaValue) {
                      showAlertToFocusElememt(
                        "事象発生エリアを選択してください",
                        "eventarea",
                      );
                    }
                  }
                } catch (error) {
                  return;
                }

                handleRegAction({
                  title: title,
                  url: url,
                  area: area,
                  shareStatus: shareStatus,
                  shares: shareBases,
                  event_area: eventAreaValue, // add 20240510 NEXCO３社連携 （事象発生エリア）
                  importance: importanceValue, // add 20240510 NEXCO３社連携 （重要度）
                  fromDate: fromDate,
                  toDate: toDate ? toDate : undefined,
                });
              }}
            >
              {buttonLabel}
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/**** AlertDialog ****/}
      <MyAlertDialog
        isOpen={isAlertOpen}
        handleOnClose={onAlertClose}
        validMessage={validMessage}
      />
      {/**** 情報連携設定 チェックボックス ****/}
      <Modal
        closeOnOverlayClick={false}
        scrollBehavior="inside"
        isOpen={isOpenShare}
        onClose={onCloseShare}
        isCentered
        size="2xl"
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mb={0} pb={0}>
            情報連携設定
          </ModalHeader>
          <ModalBody mt={3} pt={0}>
            <MyInfoShareCheckBox
              area={area}
              baseList={shareBaseList}
              handleOnChange={handleCangeShareSetting}
              handleOnClose={onCloseShare}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
